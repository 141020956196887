<section
  class="collection-icon"
  [ngClass]="{
    'cursor-pointer': enablePointerCursorHoverIcon,
    small: displayType === DisplayType.SMALL,
    big: displayType === DisplayType.BIG,
  }"
  (click)="handleClick()"
>
  @for (index of iconsGrid; track index) {
    <div class="icon-tile">
      @if (icons && icons[index]) {
        <img [src]="icons[index]" alt="app icon" />
      }
    </div>
  }
</section>
