import {NavigationItem} from '../../interfaces/navigation-items';

/**
 * When adding a new route to the applpication, add a new NavigationItem to the NavigationItems-Array.
 * This ensures the header-component will be able to display the name of the current route.
 */
export class NavigationSetup {
  public static START_PAGE: NavigationItem = {
    path: '',
    name: 'Start Page',
    icon: 'boschicon-bosch-ic-home',
    routerLink: '/',
  };

  public static APP_FINDER: NavigationItem = {
    path: 'apps',
    name: 'App Finder',
    icon: 'boschicon-bosch-ic-batch',
    routerLink: '/apps',
  };

  public static APP_DETAIL_PAGE: NavigationItem = {
    path: 'apps/:id',
    name: 'App Details',
    icon: '',
    routerLink: '/apps',
    parent: NavigationSetup.APP_FINDER,
  };

  public static APP_MANAGER_PAGE: NavigationItem = {
    path: 'app-manager',
    name: 'App Manager',
    icon: 'boschicon-bosch-ic-desktop-apps',
    routerLink: '/app-manager',
    hideOnMobile: true,
  };

  public static APP_INTEGRATOR_PAGE: NavigationItem = {
    path: 'app-integrator',
    name: 'App Creator',
    routerLink: '/app-integrator',
    hideFooter: true,
    hideSideNavigation: true,
  };

  public static CREATE_APP: NavigationItem = {
    path: 'create',
    name: 'App Creator',
    routerLink: '/create',
  };

  public static EDIT_APP: NavigationItem = {
    path: 'edit/:id',
    name: 'App Creator',
    routerLink: 'edit',
  };

  public static COLLECTION_DETAIL_PAGE: NavigationItem = {
    path: 'collection/:id',
    name: 'Collection Detail',
    routerLink: '/collection',
    parent: NavigationSetup.APP_FINDER,
  };

  public static FEEDBACK_PAGE: NavigationItem = {
    path: 'feedback',
    name: 'Feedback',
    icon: 'boschicon-bosch-ic-thumb-up-down',
    href: 'https://boschdigital.qualtrics.com/jfe/form/SV_et9eWrQTWuzZym2?Offering=NG+Portal&OfferingID=FB-0000055&Source=NG+Portal&SourceCategory=Use&Stage=Prod&SurveyStyle=Fullscreen',
    hideOnOtherNavigationItems: [NavigationSetup.APP_DETAIL_PAGE, NavigationSetup.COLLECTION_DETAIL_PAGE],
  };

  public static DOCUMENTATION: NavigationItem = {
    path: 'documentation',
    name: 'Documentation',
    icon: '',
    href: 'https://inside-docupedia.bosch.com/confluence/x/G7JVjQ',
  };

  public static SUPPORT: NavigationItem = {
    path: 'support',
    name: 'Support',
    icon: '',
    href: 'https://inside-docupedia.bosch.com/confluence/x/95xylw',
  };

  public static HELP_PAGE: NavigationItem = {
    path: 'help',
    name: 'Help',
    icon: 'boschicon-bosch-ic-question-frame',
    routerLink: '/help',
    isOpenGroup: false,
  };

  public static RESET_START_PAGE: NavigationItem = {
    path: '',
    name: 'Reset Start Page',
    icon: '',
    routerLink: '/',
    hideOnMobile: true,
  };

  public static ADMIN_UI_PAGE: NavigationItem = {
    path: 'admin',
    name: 'Admin UI',
    icon: '',
    routerLink: '/admin',
    hideOnMobile: true,
  };

  public static PROFILE_PAGE: NavigationItem = {
    path: 'profile',
    name: 'Profile',
    icon: 'boschicon-bosch-ic-my-brand-frame',
    routerLink: '/profile',
    isOpenGroup: false,
  };

  public static LAUNCHPAD_IMPORT_PAGE: NavigationItem = {
    path: 'launchpad-import',
    name: 'Launchpad-Import',
    icon: 'boschicon-bosch-ic-my-brand-frame',
    routerLink: '/launchpad-import',
    isOpenGroup: false,
    hideSideNavigation: true,
  };

  public static COLLECTION_INTEGRATOR_PAGE: NavigationItem = {
    path: 'collection-integrator',
    name: 'Collection Creator',
    routerLink: '/collection-integrator',
    hideFooter: true,
    hideSideNavigation: true,
  };

  public static CREATE_COLLECTION: NavigationItem = {
    path: 'create',
    name: 'Collection Creator',
  };

  public static EDIT_COLLECTION: NavigationItem = {
    path: 'edit/:id',
    name: 'Collection Creator',
    routerLink: 'edit',
  };


}
