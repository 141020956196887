import {
  Injectable,
  Signal,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { FinderFilters } from "../../interfaces/filters.interface";
import { TagFilterInterface } from '../../interfaces/tags-filter.interface';
import { Subject, takeUntil } from 'rxjs';
import { AppInterface } from 'src/app/core/interfaces/app.interface';
import { ScrollService } from '../../../../core/services/scroll-service/scroll.service';
import { SideNavigationService } from 'src/app/core/services/navigation-service/side-navigation.service';
import { NavigationFilterStateService } from '../../../../core/services/navigation-filter-state/navigation-filter-state.service';
import { CATEGORIES } from '../../../../core/constants/categories.constant';
import { AppsSearchService } from '../../../../core/services/apps-search-service/apps-search.service';
import { SearchCriteria } from '../../../../core/interfaces/search-criteria.interface';
import { AppDefaultFieldCriteria } from '../../../../core/services/apps-search-service/criterias/app-default-field-criteria';
import { AppTitleCriteria } from '../../../../core/services/apps-search-service/criterias/app-title-criteria';
import { AppNameCriteria } from '../../../../core/services/apps-search-service/criterias/app-name-criteria';
import { AppDescriptionCriteria } from '../../../../core/services/apps-search-service/criterias/app-description-criteria';
import { AppSubtitleCriteria } from '../../../../core/services/apps-search-service/criterias/app-subtitle-criteria';
import { AppKeywordsCriteria } from '../../../../core/services/apps-search-service/criterias/app-keywords-criteria';
import { AppCountriesCriteria } from '../../../../core/services/apps-search-service/criterias/app-countries-criteria';

@Injectable({
  providedIn: 'root',
})
export class AppFinderFilterService {
  filterOpened: boolean = false;
  category: WritableSignal<string> = signal('');
  clientSupport: WritableSignal<string> = signal('');
  tags: WritableSignal<string[]> = signal([]);
  availability: WritableSignal<string[]> = signal([]);

  clearFiltersEvent$: Subject<void> = new Subject<void>();

  private filters: Signal<FinderFilters> = computed(() => {
    return {
      category: this.category(),
      clientSupport: this.clientSupport(),
      tags: this.tags(),
      availability: this.availability(),
    };
  });

  numberOfFiltersApplied: Signal<number> = computed(() => {
    return Object.values(this.filters())
      .flatMap((_f) => _f)
      .filter((_f) => !!_f).length;
  });

  constructor(
    private searchService: AppsSearchService,
    private scrollService: ScrollService,
    private sideNavigationService: SideNavigationService,
    private navigationFilterStateService: NavigationFilterStateService,
  ) { }

  openFilters(): void {
    this.filterOpened = true;
    this.scrollService.disableScroll();

    this.navigationFilterStateService.setMenuBarState(
      this.sideNavigationService.isSideNavOpen(),
    );

    //We have to close the sideNav here, due to sideNav behaviour
    this.sideNavigationService.closeSideNav();
  }

  hasActiveFilters(): boolean {
    const filters = this.filters();
    return (
      (filters.category?.length ?? 0) > 0 ||
      (filters.clientSupport?.length ?? 0) > 0 ||
      (filters.tags?.length ?? 0) > 0
    );
  }

  closeFilters(): void {
    this.filterOpened = false;
    this.scrollService.enableScroll();
  }

  setCategory(_category: string): void {
    const category = CATEGORIES.find(
      (cat) => cat.value.toUpperCase() === _category.toUpperCase(),
    );
    if (category) {
      this.category.set(category.value);
    } else {
      this.category.set('');
    }
  }

  setClientSupport(_clientSupport: string): void {
    this.clientSupport.set(_clientSupport);
  }

  setTags(_tags: TagFilterInterface[]): void {
    this.tags.set(
      _tags.filter((_tag) => _tag.selected).map((_tag) => _tag.name),
    );
  }

  setAvailability(_availability: string[]): void {
    this.availability.set(_availability);
  }

  getFilters(): Signal<FinderFilters> {
    return this.filters;
  }

  clearFilters(): void {
    this.setCategory('');
    this.setClientSupport('');
    this.setTags([]);
    this.setAvailability([]);
  }

  clearFiltersListener(destroyRef: Subject<void>, action: () => void): void {
    this.clearFiltersEvent$
      .asObservable()
      .pipe(takeUntil(destroyRef))
      .subscribe(action);
  }

  filterApps(allApps: Signal<AppInterface[]>): AppInterface[] {
    const { category, clientSupport, tags, availability } = this.filters();

    let filteredApps = allApps();
    const anyClientApps = this.filterAppsByProperty(
      'ANY_CLIENT',
      filteredApps,
      'clientSupport',
    );

    filteredApps = this.filterAppsByProperty(
      clientSupport,
      filteredApps,
      'clientSupport',
    );
    // Merging anyClientApps with filteredApps
    filteredApps = anyClientApps.reduce(
      (_mergedApps: AppInterface[], _currentApp: AppInterface) => {
        if (!_mergedApps.some((app) => app.appId === _currentApp.appId)) {
          _mergedApps.push(_currentApp);
        }
        return _mergedApps;
      },
      filteredApps,
    );

    filteredApps = this.filterAppsByProperty(
      category === 'All categories' ? undefined : category,
      filteredApps,
      'category',
    );

    if (tags?.length) {
      filteredApps = filteredApps.filter((_app) => {
        return tags.every((_tag) => _app.keywords?.includes(_tag));
      });
    }

    if (availability?.length) {
      filteredApps = filteredApps.filter((_app) => {
        return availability.some((_country) => {
          return !_app.countries?.length || _app.countries.includes(_country);
        });
      });
    }

    return filteredApps;
  }


  private filterAppsByProperty<K extends keyof AppInterface>(
    filterQuery: string | undefined,
    apps: AppInterface[],
    property: K,
  ): AppInterface[] {
    if (filterQuery) {
      return this.searchService.search(
        apps.filter((app) => app[property]), // Exclude apps with null or undefined category
        filterQuery,
        [this.mapAppFieldToSearchCriteria(property)],
      );
    }
    return apps;
  }

  private mapAppFieldToSearchCriteria<K extends keyof AppInterface>(
    property: K,
  ): SearchCriteria {
    switch (property) {
      case 'title':
        return new AppTitleCriteria();
      case 'appName':
        return new AppNameCriteria();
      case 'description':
        return new AppDescriptionCriteria();
      case 'subtitle':
        return new AppSubtitleCriteria();
      case 'keywords':
        return new AppKeywordsCriteria();
      case 'countries':
        return new AppCountriesCriteria();
      default:
        return new AppDefaultFieldCriteria(property);
    }
  }
}
