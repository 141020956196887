import { Component, Input, Signal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartPageSearchResults } from '../../interfaces/start-page-search-results.interface';
import { SearchResultsService } from 'src/app/pages/start-page/services/search-results.service';
import { StartPageResultComponent } from "./components/start-page-result/start-page-result.component";
import { AppsFinderResultComponent } from "./components/apps-finder-result/apps-finder-result.component";

@Component({
  selector: 'search-result',
  standalone: true,
  imports: [
    CommonModule,
    StartPageResultComponent,
    AppsFinderResultComponent
],
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent {
  @Input() searchResults: Signal<StartPageSearchResults> = signal({
    appFinder: [],
    collectionFinder: [],
    startPage: [],
    bookmark: [],
  });
  @Input() openSearchResult: boolean | number = false;

  constructor(
    protected searchResultsService: SearchResultsService
  ) {}


  closeSearchResults(): void {
    this.searchResultsService.shouldOpenSearch.next(false);
    this.openSearchResult = false;
  }

  showSearchResult(): boolean {
    if (typeof this.openSearchResult === 'boolean') {
      return this.openSearchResult;
    } else {
      return this.openSearchResult > 2;
    }
  }
}
