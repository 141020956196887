import {AppInterface} from '../../../core/interfaces/app.interface';
import {computed, Injectable, signal, Signal, WritableSignal} from "@angular/core";
import {WidgetUIService} from './widget-ui.service';
import {CollectionDetailsDTO, CollectionFinderItemDTO} from "../../../core/interfaces/collection.interface";
import {FolderUiService} from "./folder-ui.service";
import { CollectionApiService } from 'src/app/core/services/collection-service/collection-api.service';

@Injectable({
  providedIn: 'root',
})
export class CollectionUIService {

  public collectionDetails: WritableSignal<CollectionDetailsDTO[]> = signal([]);
  public collectionFinderList: WritableSignal<CollectionFinderItemDTO[]> = signal([]);

  public currentlySelectedCollection: Signal<CollectionDetailsDTO | undefined> = computed(() => {
    const referenceID = this.foldersUIService.getSelectedFolder()()?.referenceID;
    if (!referenceID) return undefined;
    return this.collectionDetails().find((_collectionDetail) => _collectionDetail.id === referenceID);
  });

  public currentlySelectedCollectionApps: Signal<AppInterface[]> = computed(() => {
    if (!this.currentlySelectedCollection()) return [];

    const collectionAppIDsSet: number[] = this.currentlySelectedCollection()?.apps ?? [];
    const appsMap = new Map(
      this.widgetUIService.apps().map(_app => [Number(_app.appId), _app])
    );
    return collectionAppIDsSet
      .filter(appId => appsMap.has(appId))
      .map(appId => appsMap.get(appId)!);
  });

  public allAppsOfFollowedCollections: Signal<AppInterface[]> = computed(() => {
    const appIds: number[] = [];
    for (const collectionDetail of this.collectionDetails()) {
      appIds.push(...collectionDetail.apps);
    }
    const collectionAppIDsSet: Set<number> = new Set(appIds);
    return this.widgetUIService.apps().filter(_app => collectionAppIDsSet.has(Number(_app.appId)));
  });

  constructor(
    private widgetUIService: WidgetUIService,
    private foldersUIService: FolderUiService,
    private collectionApiService: CollectionApiService,
  ) {
    this.fetchCollectionDetails();
    this.fetchCollectionFinderList();
  }

  public fetchCollectionFinderList() {
    this.collectionApiService.listFinder().subscribe(_collectionFinderItems => {
      this.collectionFinderList.set(_collectionFinderItems);
    });
  }

  public fetchCollectionDetails() {
    this.collectionApiService.getAddedCollectionsForCurrentUser().subscribe(
      _collectionDetails => {
        this.collectionDetails.set(_collectionDetails);
      }
    );
  }
}
