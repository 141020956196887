import { Routes } from '@angular/router';
import { integratorGuard } from './core/guards/integrator.guard';
import { editAppGuard } from './core/guards/edit-app.guard';
import { NavigationSetup } from './core/services/navigation-items-service/navigation-setup';

export const routes: Routes = [
  {
    path: NavigationSetup.START_PAGE.path,
    loadComponent: () =>
      import('./pages/start-page/start-page.component').then(
        (c) => c.StartPageComponent,
      ),
  },
  {
    path: NavigationSetup.APP_FINDER.path,
    loadComponent: () =>
      import('./pages/app-finder-page/app-finder-page.component').then(
        (c) => c.AppFinderPageComponent,
      ),
  },
  {
    path: NavigationSetup.APP_DETAIL_PAGE.path,
    loadComponent: () =>
      import('./pages/app-details-page/app-details-page.component').then(
        (c) => c.AppDetailsPageComponent,
      ),
  },
  {
    path: NavigationSetup.APP_MANAGER_PAGE.path,
    loadComponent: () =>
      import('./pages/manager/manager.component').then(
        (c) => c.ManagerComponent,
      ),
  },
  {
    path: NavigationSetup.COLLECTION_DETAIL_PAGE.path,
    loadComponent: () =>
      import('./pages/collection-detail-page/collection-detail-page.component').then(
        (c) => c.CollectionDetailPageComponent,
      ),
  },
  {
    path: NavigationSetup.ADMIN_UI_PAGE.path,
    loadComponent: () =>
      import('./pages/app-admin-ui-page/app-admin-ui-page.component').then(
        (c) => c.AppAdminUiPageComponent,
      ),
  },
  {
    path: NavigationSetup.APP_INTEGRATOR_PAGE.path,
    children: [
      {
        path: '',
        redirectTo: NavigationSetup.CREATE_APP.path,
        pathMatch: 'full',
      },
      {
        path: NavigationSetup.CREATE_APP.path,
        loadComponent: () =>
          import('./pages/app-integrator-page/integrator.component').then(
            (c) => c.IntegratorComponent,
          ),
      },
      {
        path: NavigationSetup.EDIT_APP.path,
        loadComponent: () =>
          import('./pages/app-integrator-page/integrator.component').then(
            (c) => c.IntegratorComponent,
          ),
        canActivate: [editAppGuard],
      },
      {
        path: '**',
        redirectTo: NavigationSetup.CREATE_APP.path,
        pathMatch: 'full',
      },
    ],
    canActivate: [integratorGuard],
  },
  {
    path: NavigationSetup.COLLECTION_INTEGRATOR_PAGE.path,
    children: [
      {
        path: '',
        redirectTo: NavigationSetup.CREATE_COLLECTION.path,
        pathMatch: 'full',
      },
      {
        path: NavigationSetup.CREATE_COLLECTION.path,
        loadComponent: () =>
          import('./pages/collection-integrator-page/collection-integrator-page.component').then(
            (c) => c.CollectionIntegratorPageComponent,
          ),
      },
      {
        path: NavigationSetup.EDIT_COLLECTION.path,
        loadComponent: () =>
          import('./pages/collection-integrator-page/collection-integrator-page.component').then(
            (c) => c.CollectionIntegratorPageComponent,
          ),
      },
      {
        path: '**',
        redirectTo: NavigationSetup.CREATE_COLLECTION.path,
        pathMatch: 'full',
      },
    ],
    canActivate: [integratorGuard],
  },
  {
    path: NavigationSetup.LAUNCHPAD_IMPORT_PAGE.path,
    loadComponent: () =>
      import('./pages/launchpad-import/launchpad-import.component').then(
        (c) => c.LaunchpadImportComponent,
      ),
  },
];
