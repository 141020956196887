import { OwnershipType } from '../enums/ownership-type.enum';
import {
  Feedback,
  Support,
} from './app-details-interfaces/app-details.interface';

export enum ETileType {
  Card = 'CARD',
  Static = 'STATIC',
  News = 'NEWS',
  Search = 'SEARCH',
  List = 'LIST',
}

export enum EWindowOpenBehavior {
  Self = 'SELF',
  NewWindow = 'NEW_TAB',
}

export enum EClientSupport {
  ANY_CLIENT = 'ANY_CLIENT',
  INTERNET_ONLY = 'INTERNET_ONLY',
  INTRANET_ONLY = 'INTRANET_ONLY',
}

export interface Bookmark extends AppInterface {
  icon?: string;
  color?: string;
  boschNT?: string;
  bookmarkId?: string;
}

export enum DisplayType {
  SMALL,
  BIG,
}

export enum ClickOnAppBehavior {
  OPEN_APP_DETAILS,
  OPEN_APP_DETAILS_NEW_TAB,
  OPEN_APP_BY_URL,
  NO_BEHAVIOR,
}

export interface AppInterface {
  ownerShipType?: OwnershipType;
  countAdded?: number;
  partitionKey?: string;
  id?: number;
  appId?: number;
  title?: string;
  description?: Description;
  comments?: string;
  appOwner?: AppUser;
  appCreator?: AppUser;
  appEditors?: AppUser[];
  windowOpenBehaviour?: EWindowOpenBehavior;
  integrator?: NTUser;
  creationDate?: string;
  lastModifiedDate?: string; // Used for App finder
  modifiedDate?: string;
  modifiedBy?: NTUser;
  isActive?: boolean;
  businessCritical?: boolean;
  countries?: string[];
  division?: Country[];
  dynamicTitleServiceURL?: string;
  displayRefreshInterval?: number;
  supportsDesktop?: boolean;
  supportsTablet?: boolean;
  supportsMobile?: boolean;
  category?: string;
  categoryName?: string;
  secondLevelCategory?: string;
  subtitle?: string;
  appIconURL?: string;
  hasDynamicTitle?: boolean;
  prefillInHomePage?: boolean;
  keywords: string[];
  tileInformation?: string;
  supportConcept?: SupportConcept;
  idmRoles?: IdmRole[];
  furtherInfosList?: FurtherInformation[];
  contentType?: string;
  appType?: ETileType;
  attachmentURLs?: string[];
  posX?: number;
  posY?: number;
  inDashboard?: boolean;
  appInstanceId?: string;
  folderId?: string;
  searchQuery?: string;
  instanceTileType?: ETileType;
  boschTubeLink?: string;
  isDisplayListTitle?: boolean;
  listTitle?: string;
  searchQueryUrl?: string;
  dataJsonPathList?: string;
  dataJsonPathIconUrls?: string;
  dataJsonPathText?: string;
  dataJsonPathUrl?: string;
  leanIX?: string;
  publishingConsent?: boolean;
  clientSupport?: string;
  feedbackUrl?: string;
  appURL?: string;
  appName?: string;
  isBookmark?: boolean;
  bookmarkURL?: string;
  dataSourceType?: string;
  cardTitle?: string;
  feedback?: Feedback;
  support?: Support;
  isShowOnManager?: boolean;
}

export interface IdmRole {
  system: string;
  role: string;
}

interface SupportConcept {
  id?: unknown;
  firstLevelSupport: string;
  secondLevelSupport: string;
  thirdLevelSupport: string;
}

interface Country {
  id?: string;
  name: string;
}

export interface AppUser {
  id: string;
  displayName: string;
  userPrincipalName: string;
  mail: string;
}

export interface NTUser {
  boschNT: string;
}

interface Description {
  text: string;
  language: string;
}

export interface ImportApp {
  appId: string;
  appName: string;
  importAction: string;
  status: string;
}

export interface FurtherInformation {
  title: string;
  url: string;
}

export const URL_PATTERN =
  'http(s){0,1}://(www){0,1}[a-zA-Z.0-9]+.[a-zA-Z]{2,3}(:){0,1}.*';

export const MAX_SCREENSHOTS = 5;
export const MAX_ICONS = 1;
export const MAX_ITEMS = 25;
export const MAX_ITEMS_WITH_TITLE = 20;
export const ITEMS_PER_PAGE = 5;
export const ITEMS_PER_PAGE_WITH_TITLE = 4;
