import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationHistoryService implements OnDestroy {
  private history: string[] = [];
  private routerEventsSubscription: Subscription | undefined;

  constructor(
    private location: Location,
    private router: Router,
  ) { }

  init() {
    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }

  public goBack(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/').then(() => { });
    }
  }

  public getPreviousPath(): string | undefined {
    return this.history.at(this.history.length - 2);
  }
}
