import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DisplayType } from 'src/app/core/interfaces/app.interface';
import { NgClass, NgOptimizedImage } from "@angular/common";

@Component({
  selector: 'collection-icon',
  standalone: true,
  templateUrl: './collection-icon.component.html',
  styleUrls: ['./collection-icon.component.scss'],
  imports: [NgOptimizedImage, NgClass],
})
export class CollectionIconComponent {
  @Input() enablePointerCursorHoverIcon: boolean = true;
  @Input({ required: true }) icons: string[] = [];
  @Input({ required: false }) displayType: DisplayType = DisplayType.BIG;
  @Output() iconClicked: EventEmitter<void> = new EventEmitter<void>();

  iconsGrid = [0, 1, 2, 3];

  protected handleClick() {
    this.iconClicked.emit();
  }

  protected readonly DisplayType = DisplayType;
}
