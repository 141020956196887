<div class="apps-finder-result-container">
  <span class="apps-finder-result-container-title-page">App Finder</span>

  <ul>
    @for (app of searchResults.appFinder; track app.id) {
      <li class="application-container">
        <app
          (click)="logAppSearchResult(app)"
          [app]="app"
          [showAddRemoveButton]="false"
        ></app>
      </li>
    }
  </ul>

  <ul>
    @for (collection of searchResults.collectionFinder; track collection.id) {
      <li class="collection-item" (click)="clickedCollection(collection.id)">
        <collection-icon class="ml-2 mr-4" [icons]="collection.icons" [displayType]="displayType.SMALL"></collection-icon>
        <div class="label">
          <a class="title line-clamp-2" [tooltip]="collection.title">{{ collection.title }}</a>
          <a class="subtitle line-clamp-1">{{ collection.subtitle }}</a>
        </div>
      </li>
    }
  </ul>


  <no-apps-found [showMessage]="!searchResults.appFinder.length && !searchResults.collectionFinder.length"></no-apps-found>
</div>
