import { SearchCriteria } from 'src/app/core/interfaces/search-criteria.interface';
import { AppInterface } from '../../../interfaces/app.interface';

export class AppCountriesCriteria implements SearchCriteria {
  getMatchScore(_app: AppInterface, _query: string): number {
    if (!_app.countries) return 0;
    return _app.countries.some((keyword) => {
      return keyword.toLowerCase().includes(_query);
    })
      ? 1
      : 0;
  }
}
