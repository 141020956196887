import { Component, computed, Input, Signal, signal, WritableSignal } from "@angular/core";
import { FormControl } from '@angular/forms';
import { SearchBarComponent } from '../../../../core/components/search-bar/search-bar.component';
import { SearchResultComponent } from '../../../../core/components/search-result/search-result.component';
import { PlatformService } from '../../../../core/services/platform-service/platform.service';
import { StartPageSearchResults } from '../../../../core/interfaces/start-page-search-results.interface';
import { Router } from '@angular/router';
import { StartPageSearchService } from "src/app/core/services/start-page-search-service/start-page-search.service";

@Component({
  selector: 'start-page-search-bar',
  standalone: true,
  imports: [SearchBarComponent, SearchResultComponent],
  templateUrl: './start-page-search-bar.component.html',
  styleUrl: './start-page-search-bar.component.scss',
})
export class StartPageSearchBarComponent {
  @Input({ required: true }) public isMinimal!: boolean;

  public searchQuery: WritableSignal<string> = signal('');

  protected searchResults: Signal<StartPageSearchResults> = computed(() =>
    this.startPageAppsSearchService.searchStartPage(
      this.searchQuery()
    ),
  );

  protected queryFormControl: FormControl<string | null> =
    new FormControl<string>('');

  protected onFocusSearchStartPage = false;

  constructor(
    private router: Router,
    private platformService: PlatformService,
    private startPageAppsSearchService: StartPageSearchService
  ) {
    this.addRouterEvent();
  }

  private addRouterEvent(): void {
    this.router.events.subscribe(() => {
      // clear searchQuery when change navigate
      this.searchQuery.set('');
    });
  }

  updateSearchQuery(_query: string): void {
    this.searchQuery.set(_query);
  }

  focusChangeEvent(_event: boolean): void {
    this.onFocusSearchStartPage = _event;
  }

  openSearchResult(): boolean | number {
    if (this.platformService.isMobile()) {
      return this.searchQuery().length;
    } else {
      return this.searchQuery().length > 2 && this.onFocusSearchStartPage;
    }
  }
}
