import { SearchCriteria } from 'src/app/core/interfaces/search-criteria.interface';
import { AppInterface } from '../../../interfaces/app.interface';

export class AppDefaultFieldCriteria implements SearchCriteria {
  constructor(private fieldName: keyof AppInterface) {}
  getMatchScore(_app: AppInterface, _query: string): number {
    const field = _app[this.fieldName];
    if (field) {
      // string
      if (typeof field === 'string') {
        return field.toLowerCase().includes(_query) ? 1 : 0;
      }
      // number
      if (typeof field === 'number') {
        return (field + '').toLowerCase().includes(_query) ? 1 : 0;
      }
    }
    // no field -> do not regard
    // all others -> should have dedicated search criteria; do not regard
    return 0;
  }
}
