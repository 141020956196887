import { Injectable } from '@angular/core';
import { AppInterface } from '../../interfaces/app.interface';
import { SearchCriteria } from '../../interfaces/search-criteria.interface';
import { SearchableAppInterface } from './searchable-app.interface';

@Injectable({
  providedIn: 'root',
})
export class AppsSearchService {
  search(
    _apps: AppInterface[],
    _query: string,
    _criteria: SearchCriteria[],
  ): AppInterface[] {
    if (_query === '') return _apps;
    const searchQuery = this.getSearchQuery(_query);
    return _apps
      .map((_app) =>
        this.calculateMatchScore(
          _app as SearchableAppInterface,
          searchQuery,
          _criteria,
        ),
      )
      .filter((_app) => _app.matchScore > 0)
      .sort(this.sortByMatchScore);
  }

  private calculateMatchScore(
    _app: SearchableAppInterface,
    _searchQuery: string[],
    _criteria: SearchCriteria[],
  ): SearchableAppInterface {
    let matchScore: number = 0;
    for (const criteria of _criteria) {
      let criteriaMatchScore = 0;
      for (const _queryPart of _searchQuery) {
        const queryPartMatch = criteria.getMatchScore(_app, _queryPart);
        // all query parts should match (logical AND )
        if (queryPartMatch === 0) {
          criteriaMatchScore = 0;
          break;
        } else {
          criteriaMatchScore = criteriaMatchScore + queryPartMatch;
        }
      }
      matchScore = matchScore + criteriaMatchScore;
    }
    return { ..._app, matchScore };
  }
  private sortByMatchScore(
    a: SearchableAppInterface,
    b: SearchableAppInterface,
  ): number {
    return b.matchScore - a.matchScore;
  }

  private getSearchQuery(_searchQuery: string): string[] {
    return _searchQuery
      .toLowerCase()
      .split(' ')
      .filter((t) => t.length > 0);
  }
}
