import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  CollectionDetailsDTO,
  CollectionFinderItemDTO,
  CollectionManagerItemDTO,
  CollectionSaveDTO
} from "../../interfaces/collection.interface";
import { Observable,take } from "rxjs";
import { AppInterface } from "../../interfaces/app.interface";

@Injectable({
  providedIn: 'root',
})
export class CollectionApiService {
  private collectionsEndpoint = environment.baseUrl + '/collections';
  private collectionInstancesEndpoint = environment.baseUrl + '/me/collection-instances';
  private collectionFinderEndpoint = environment.baseUrl + '/me/collectionFinder';
  private collectionManagerEndpoint = environment.baseUrl + '/collectionManager';

  constructor(private http: HttpClient) {}

  public listFinder() {
    return this.http.get<CollectionFinderItemDTO[]>(this.collectionFinderEndpoint).pipe(take(1));
  }

  public listManager() {
    return this.http.get<CollectionManagerItemDTO[]>(this.collectionManagerEndpoint).pipe(take(1));
  }

  public details(_id: number) {
    return this.http.get<CollectionDetailsDTO>(`${this.collectionsEndpoint}/${_id}`).pipe(take(1));
  }

  public getCollectionApps(_collectionId: number) {
    return this.http.get<AppInterface[]>(`${this.collectionsEndpoint}/${_collectionId}/apps`).pipe(take(1));
  }

  public create(_collection: CollectionSaveDTO) {
    return this.http.post<CollectionDetailsDTO>(this.collectionsEndpoint, _collection).pipe(take(1));
  }

  public update(_id: number, _data: CollectionSaveDTO) {
    return this.http.put<CollectionDetailsDTO>(`${this.collectionsEndpoint}/${_id}`, _data).pipe(take(1));
  }

  public deleteCollection(_id: number) {
    return this.http.delete<void>(`${this.collectionsEndpoint}/${_id}`).pipe(take(1));
  }

  public addCollection(_id: number) {
    return this.http.post<void>(`${this.collectionInstancesEndpoint}/${_id}`,undefined).pipe(take(1));
  }

  public removeCollection(_id: number) {
    return this.http.delete<void>(`${this.collectionInstancesEndpoint}/${_id}`).pipe(take(1));
  }

  public getUserAddedCollectionApps() {
    return this.http.get<number[]>(`${this.collectionInstancesEndpoint}/apps`).pipe(take(1));
  }
  
  public getAddedCollectionsForCurrentUser(): Observable<CollectionDetailsDTO[]> {
    return this.http.get<CollectionDetailsDTO[]>(`${this.collectionInstancesEndpoint}`).pipe(take(1));
  }
}
