import { Component, Input } from '@angular/core';
import { ApplicationComponent } from "../../../app/application.component";
import { NoAppsFoundComponent } from "../no-apps-found/no-apps-found.component";
import { LogEventService } from "../../../../services/log-event-service/log-event.service";
import { AppInterface, DisplayType } from "../../../../interfaces/app.interface";
import { CollectionIconComponent } from "../../../collection/collection-icon/collection-icon.component";
import { TooltipDirective } from "../../../../directives/tooltip.directive";
import { NavigationItemsService } from "../../../../services/navigation-items-service/navigation-items.service";
import { Router } from "@angular/router";
import { StartPageSearchResults } from 'src/app/core/interfaces/start-page-search-results.interface';

@Component({
  selector: 'apps-finder-result',
  standalone: true,
  imports: [ApplicationComponent, NoAppsFoundComponent, CollectionIconComponent, TooltipDirective],
  templateUrl: './apps-finder-result.component.html',
  styleUrls: ['./apps-finder-result.component.scss'],
})
export class AppsFinderResultComponent {
  @Input() searchResults!: StartPageSearchResults;

  constructor(private logEventService: LogEventService,
              private navigationItems: NavigationItemsService,
              private router: Router,) {
  }

  logAppSearchResult(app: AppInterface): void {
    this.logEventService.logServer('app', 'searchResult', app.appId + '');
  }

  protected clickedCollection(_collectionId: number){
    return this.router.navigate([
      this.navigationItems.getCollectionDetail().routerLink,
      _collectionId,
    ]);
  }

  protected readonly displayType = DisplayType;
}
