import { Injectable } from "@angular/core";
import { CollectionFinderItemDTO } from "../../interfaces/collection.interface";
import { SearchCriteria } from 'src/app/core/interfaces/search-criteria.interface';
import { SearchableCollectionInterface } from "./searchable-collection.interface";

@Injectable({
    providedIn: 'root',
})
export class CollectionsSearchService {
    search(
        _collections: CollectionFinderItemDTO[],
        _query: string,
        _criteria?: SearchCriteria[],
      ): CollectionFinderItemDTO[] {
        if (_query === '') return _collections;
        const searchQuery = this.getSearchQuery(_query);
        if(_criteria)
        {
          return _collections
          .map((_collection) =>
            this.calculateMathScore(
              _collection as SearchableCollectionInterface,
              searchQuery,
              _criteria,
            ),
          )
          .filter((_collection) => _collection.matchScore > 0)
          .sort(this.sortByMatchScore);
        }
        return _collections.filter(x => x.title.toLowerCase().indexOf(_query.toLowerCase()) > -1 || (x.subtitle || '').toLowerCase().indexOf(_query.toLowerCase()) > -1)
      }

      private calculateMathScore(
        _collection: SearchableCollectionInterface,
        _searchQuery: string[],
        _criteria?: SearchCriteria[],
      ): SearchableCollectionInterface {
        let matchScore: number = 0;
        for (const criteria of _criteria ?? []) {
          let criteriaMatchScore = 0;
          for (const _queryPart of _searchQuery) {
            const queryPartMatch = criteria.getMatchScore(_collection, _queryPart);
            // all query parts should match (logical AND )
            if (queryPartMatch === 0) {
              criteriaMatchScore = 0;
              break;
            } else {
              criteriaMatchScore = criteriaMatchScore + queryPartMatch;
            }
          }
          matchScore = matchScore + criteriaMatchScore;
        }
        return { ..._collection, matchScore };
      }
      private sortByMatchScore(
        a: SearchableCollectionInterface,
        b: SearchableCollectionInterface,
      ): number {
        return b.matchScore - a.matchScore;
      }

      private getSearchQuery(_searchQuery: string): string[] {
        return _searchQuery
          .toLowerCase()
          .split(' ')
          .filter((t) => t.length > 0);
      }
}
